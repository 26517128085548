import React, { Suspense, useEffect } from 'react';
import { useRoutes, useSearchParams, Navigate } from 'react-router-dom';

const Home = React.lazy(() => import('../pages/Home'));
const Terms = React.lazy(() => import('../pages/terms'));
const Privacy = React.lazy(() => import('../pages/privacy'));
const Ecomm = React.lazy(() => import('../pages/solutions/Ecomm'));
const Brands = React.lazy(() => import('../pages/solutions/Brands'));

const About = React.lazy(() => import('../pages/aboutus'));
const profitCalculator = React.lazy(() => import('../pages/profit-calculator'));
const Contact = React.lazy(() => import('../pages/other/Contact'));
const NotFound = React.lazy(() => import('../pages/Home/NotFound'));

const loading = () => <div></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const [router] = useSearchParams();

    useEffect(() => {
        let http_referer = document.referrer;
        let referer: Record<string, string> = {};
        const routerEntries: [string, string][] = Array.from(router.entries());
        for (const [key, value] of routerEntries) {
            referer[key] = value;
        }
        if (Object.keys(referer).length !== 0) {
            sessionStorage.setItem("referer", JSON.stringify(referer));
        }
        if (http_referer !== "") {
            sessionStorage.setItem("http_referer", http_referer);
        } else {
            sessionStorage.setItem("http_referer", window.location.href);
        }
    }, [router]);

    return useRoutes([
        {
            // root route
            path: '/',
            element: <LoadComponent component={Home} />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'about',
                    element: <LoadComponent component={About} />,
                },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact} />,
                },
                {
                    path: 'solutions',
                    children: [
                        { path: 'publishers', element: <Navigate to="/solutions/xuno-for-monetisation" replace /> }, 
                        { path: 'ecommerce', element: <Navigate to="/solutions/xuno-for-monetisation" replace /> }, 
                        { path: 'xuno-for-monetisation', element: <LoadComponent component={Ecomm} /> },
                        { path: 'brands', element: <Navigate to="/solutions/xuno-for-brands" replace /> }, 
                        { path: 'xuno-ads', element: <Navigate to="/solutions/xuno-for-brands" replace /> }, 
                        { path: 'xuno-for-brands', element: <LoadComponent component={Brands} /> },
                    ],
                },
                {
                    path: 'profit-calculator',
                    element: <LoadComponent component={profitCalculator} />,
                },
                {
                    path: 'terms',
                    element: <LoadComponent component={Terms} />,
                },
                {
                    path: 'privacy',
                    element: <LoadComponent component={Privacy} />,
                },
            ],
        },
        {
            path: '*',
            element: <LoadComponent component={NotFound} />, 
        },
    ]);
};

export default AllRoutes;